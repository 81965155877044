import { FC } from 'react';
import ReactLoading from 'react-loading';
import { atom, useAtomValue } from 'jotai';

export const isLoadingAtom = atom(false);

export const Loading: FC = () => {
  const isLoading = useAtomValue(isLoadingAtom);
  if (!isLoading) {
    return null;
  }

  return <div className={'fixed w-full h-full left-0 top-0 z-50 flex justify-center items-center'}>
    <ReactLoading type={'spinningBubbles'} color={'white'} className={'z-50'} width={80} height={80}/>
    <div className={'absolute w-full h-full left-0 top-0 bg-black opacity-25'} />
  </div>
}
