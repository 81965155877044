export const BIBLE_BOOK_TITLES = [
  '창세기',
  '출애굽기',
  '레위기',
  '민수기',
  '신명기',
  '여호수아',
  '사사기',
  '룻기',
  '사무엘상',
  '사무엘하',
  '열왕기상',
  '열왕기하',
  '역대상',
  '역대하',
  '에스라',
  '느헤미야',
  '에스더',
  '욥기',
  '시편',
  '잠언',
  '전도서',
  '아가',
  '이사야',
  '예레미야',
  '예레미야애가',
  '에스겔',
  '다니엘',
  '호세아',
  '요엘',
  '아모스',
  '오바댜',
  '요나',
  '미가',
  '나훔',
  '하박국',
  '스바냐',
  '학개',
  '스가랴',
  '말라기',
  '마태복음',
  '마가복음',
  '누가복음',
  '요한복음',
  '사도행전',
  '로마서',
  '고린도전서',
  '고린도후서',
  '갈라디아서',
  '에베소서',
  '빌립보서',
  '골로새서',
  '데살로니가전서',
  '데살로니가후서',
  '디모데전서',
  '디모데후서',
  '디도서',
  '빌레몬서',
  '히브리서',
  '야고보서',
  '베드로전서',
  '베드로후서',
  '요한일서',
  '요한2서',
  '요한3서',
  '유다서',
  '요한계시록',
];

export const BIBLE_CHAPTER_MAP: { [chapter: string]: number } = {
  '창세기': 50,
  '출애굽기': 40,
  '레위기': 27,
  '민수기': 36,
  '신명기': 34,
  '여호수아': 24,
  '사사기': 21,
  '룻기': 4,
  '사무엘상': 31,
  '사무엘하': 24,
  '열왕기상': 22,
  '열왕기하': 25,
  '역대상': 29,
  '역대하': 36,
  '에스라': 10,
  '느헤미야': 13,
  '에스더': 10,
  '욥기': 42,
  '시편': 150,
  '잠언': 31,
  '전도서': 12,
  '아가': 8,
  '이사야': 66,
  '예레미야': 52,
  '예레미야애가': 5,
  '에스겔': 48,
  '다니엘': 12,
  '호세아': 14,
  '요엘': 3,
  '아모스': 9,
  '오바댜': 1,
  '요나': 4,
  '미가': 7,
  '나훔': 3,
  '하박국': 3,
  '스바냐': 3,
  '학개': 2,
  '스가랴': 14,
  '말라기': 4,
  '마태복음': 28,
  '마가복음': 16,
  '누가복음': 24,
  '요한복음': 21,
  '사도행전': 28,
  '로마서': 16,
  '고린도전서': 16,
  '고린도후서': 13,
  '갈라디아서': 6,
  '에베소서': 6,
  '빌립보서': 4,
  '골로새서': 4,
  '데살로니가전서': 5,
  '데살로니가후서': 3,
  '디모데전서': 6,
  '디모데후서': 4,
  '디도서': 3,
  '빌레몬서': 1,
  '히브리서': 13,
  '야고보서': 5,
  '베드로전서': 5,
  '베드로후서': 3,
  '요한일서': 5,
  '요한2서': 1,
  '요한3서': 1,
  '유다서': 1,
  '요한계시록': 22,
};

export const BIBLE_MAPPING_TABLE = [
  {
    kr_short: '창',
    kr_long: '창세기',
    en_short: 'Gen',
    en_long: 'Genesis',
    verse_count: 50,
  },
  {
    kr_short: '출',
    kr_long: '출애굽기',
    en_short: 'Exo',
    en_long: 'Exodus',
    verse_count: 40,
  },
  {
    kr_short: '레',
    kr_long: '레위기',
    en_short: 'Lev',
    en_long: 'Leviticus',
    verse_count: 27,
  },
  {
    kr_short: '민',
    kr_long: '민수기',
    en_short: 'Num',
    en_long: 'Numbers',
    verse_count: 36,
  },
  {
    kr_short: '신',
    kr_long: '신명기',
    en_short: 'Deu',
    en_long: 'Deuteronomy',
    verse_count: 34,
  },
  {
    kr_short: '수',
    kr_long: '여호수아',
    en_short: 'Jos',
    en_long: 'Joshua',
    verse_count: 24,
  },
  {
    kr_short: '삿',
    kr_long: '사사기',
    en_short: 'Jdg',
    en_long: 'Judges',
    verse_count: 21,
  },
  {
    kr_short: '룻',
    kr_long: '룻기',
    en_short: 'Rut',
    en_long: 'Ruth',
    verse_count: 4,
  },
  {
    kr_short: '삼상',
    kr_long: '사무엘상',
    en_short: '1Sa',
    en_long: '1 Samuel',
    verse_count: 31,
  },
  {
    kr_short: '삼하',
    kr_long: '사무엘하',
    en_short: '2Sa',
    en_long: '2 Samuel',
    verse_count: 24,
  },
  {
    kr_short: '왕상',
    kr_long: '열왕기상',
    en_short: '1Ki',
    en_long: '1 Kings',
    verse_count: 22,
  },
  {
    kr_short: '왕하',
    kr_long: '열왕기하',
    en_short: '2Ki',
    en_long: '2 Kings',
    verse_count: 25,
  },
  {
    kr_short: '대상',
    kr_long: '역대상',
    en_short: '1Ch',
    en_long: '1 Chronicles',
    verse_count: 29,
  },
  {
    kr_short: '대하',
    kr_long: '역대하',
    en_short: '2Ch',
    en_long: '2 Chronicles',
    verse_count: 36,
  },
  {
    kr_short: '스',
    kr_long: '에스라',
    en_short: 'Ezr',
    en_long: 'Ezra',
    verse_count: 10,
  },
  {
    kr_short: '느',
    kr_long: '느헤미야',
    en_short: 'Neh',
    en_long: 'Nehemiah',
    verse_count: 13,
  },
  {
    kr_short: '에',
    kr_long: '에스더',
    en_short: 'Est',
    en_long: 'Esther',
    verse_count: 10,
  },
  {
    kr_short: '욥',
    kr_long: '욥기',
    en_short: 'Job',
    en_long: 'Job',
    verse_count: 42,
  },
  {
    kr_short: '시',
    kr_long: '시편',
    en_short: 'Psa',
    en_long: 'Psalms',
    verse_count: 150,
  },
  {
    kr_short: '잠',
    kr_long: '잠언',
    en_short: 'Pro',
    en_long: 'Proverbs',
    verse_count: 31,
  },
  {
    kr_short: '전',
    kr_long: '전도서',
    en_short: 'Ecc',
    en_long: 'Ecclesiastes',
    verse_count: 12,
  },
  {
    kr_short: '아',
    kr_long: '아가',
    en_short: 'Sos',
    en_long: 'Song of Songs',
    verse_count: 8,
  },
  {
    kr_short: '사',
    kr_long: '이사야',
    en_short: 'Isa',
    en_long: 'Isaiah',
    verse_count: 66,
  },
  {
    kr_short: '렘',
    kr_long: '예레미야',
    en_short: 'Jer',
    en_long: 'Jeremiah',
    verse_count: 52,
  },
  {
    kr_short: '애',
    kr_long: '예레미야애가',
    en_short: 'Lam',
    en_long: 'Lamentations',
    verse_count: 5,
  },
  {
    kr_short: '겔',
    kr_long: '에스겔',
    en_short: 'Eze',
    en_long: 'Ezekiel',
    verse_count: 48,
  },
  {
    kr_short: '단',
    kr_long: '다니엘',
    en_short: 'Dan',
    en_long: 'Daniel',
    verse_count: 12,
  },
  {
    kr_short: '호',
    kr_long: '호세아',
    en_short: 'Hos',
    en_long: 'Hosea',
    verse_count: 14,
  },
  {
    kr_short: '욜',
    kr_long: '요엘',
    en_short: 'Joe',
    en_long: 'Joel',
    verse_count: 3,
  },
  {
    kr_short: '암',
    kr_long: '아모스',
    en_short: 'Amo',
    en_long: 'Amos',
    verse_count: 9,
  },
  {
    kr_short: '옵',
    kr_long: '오바댜',
    en_short: 'Oba',
    en_long: 'Obadiah',
    verse_count: 1,
  },
  {
    kr_short: '욘',
    kr_long: '요나',
    en_short: 'Jon',
    en_long: 'Jonah',
    verse_count: 4,
  },
  {
    kr_short: '미',
    kr_long: '미가',
    en_short: 'Mic',
    en_long: 'Micah',
    verse_count: 7,
  },
  {
    kr_short: '나',
    kr_long: '나훔',
    en_short: 'Nah',
    en_long: 'Nahum',
    verse_count: 3,
  },
  {
    kr_short: '합',
    kr_long: '하박국',
    en_short: 'Hab',
    en_long: 'Habakkuk',
    verse_count: 3,
  },
  {
    kr_short: '습',
    kr_long: '스바냐',
    en_short: 'Zep',
    en_long: 'Zephaniah',
    verse_count: 3,
  },
  {
    kr_short: '학',
    kr_long: '학개',
    en_short: 'Hag',
    en_long: 'Haggai',
    verse_count: 2,
  },
  {
    kr_short: '슥',
    kr_long: '스가랴',
    en_short: 'Zec',
    en_long: 'Zechariah',
    verse_count: 14,
  },
  {
    kr_short: '말',
    kr_long: '말라기',
    en_short: 'Mal',
    en_long: 'Malachi',
    verse_count: 4,
  },
  { kr_short: '마', kr_long: '마태복음', en_short: 'Mat', en_long: 'Matthew', verse_count: 28, },
  { kr_short: '막', kr_long: '마가복음', en_short: 'Mar', en_long: 'Mark', verse_count: 16, },
  { kr_short: '눅', kr_long: '누가복음', en_short: 'Luk', en_long: 'Luke', verse_count: 24, },
  { kr_short: '요', kr_long: '요한복음', en_short: 'Joh', en_long: 'John', verse_count: 21, },
  { kr_short: '행', kr_long: '사도행전', en_short: 'Act', en_long: 'Acts', verse_count: 28, },
  { kr_short: '롬', kr_long: '로마서', en_short: 'Rom', en_long: 'Romans', verse_count: 16, },
  { kr_short: '고전', kr_long: '고린도전서', en_short: '1Co', en_long: '1 Corinthians', verse_count: 16, },
  { kr_short: '고후', kr_long: '고린도후서', en_short: '2Co', en_long: '2 Corinthians', verse_count: 13, },
  { kr_short: '갈', kr_long: '갈라디아서', en_short: 'Gal', en_long: 'Galatians', verse_count: 6, },
  { kr_short: '엡', kr_long: '에베소서', en_short: 'Eph', en_long: 'Ephesians', verse_count: 6, },
  { kr_short: '빌', kr_long: '빌립보서', en_short: 'Phi', en_long: 'Philippians', verse_count: 4, },
  { kr_short: '골', kr_long: '골로새서', en_short: 'Col', en_long: 'Colossians', verse_count: 4, },
  { kr_short: '살전', kr_long: '데살로니가전서', en_short: '1Th', en_long: '1 Thessalonians', verse_count: 5, },
  { kr_short: '살후', kr_long: '데살로니가후서', en_short: '2Th', en_long: '2 Thessalonians', verse_count: 3, },
  { kr_short: '딤전', kr_long: '디모데전서', en_short: '1Ti', en_long: '1 Timothy', verse_count: 6, },
  { kr_short: '딤후', kr_long: '디모데후서', en_short: '2Ti', en_long: '2 Timothy', verse_count: 4, },
  { kr_short: '딛', kr_long: '디도서', en_short: 'Tit', en_long: 'Titus', verse_count: 3, },
  { kr_short: '몬', kr_long: '빌레몬서', en_short: 'Phm', en_long: 'Philemon', verse_count: 1, },
  { kr_short: '히', kr_long: '히브리서', en_short: 'Heb', en_long: 'Hebrews', verse_count: 13, },
  { kr_short: '약', kr_long: '야고보서', en_short: 'Jam', en_long: 'James', verse_count: 5, },
  { kr_short: '벧전', kr_long: '베드로전서', en_short: '1Pe', en_long: '1 Peter', verse_count: 5, },
  { kr_short: '벧후', kr_long: '베드로후서', en_short: '2Pe', en_long: '2 Peter', verse_count: 3, },
  { kr_short: '요1', kr_long: '요한일서', en_short: '1Jo', en_long: '1 John', verse_count: 5, },
  { kr_short: '요2', kr_long: '요한이서', en_short: '2Jo', en_long: '2 John', verse_count: 1, },
  { kr_short: '요3', kr_long: '요한삼서', en_short: '3Jo', en_long: '3 John', verse_count: 1, },
  { kr_short: '유', kr_long: '유다서', en_short: 'Jud', en_long: 'Jude', verse_count: 1, },
  { kr_short: '계', kr_long: '요한계시록', en_short: 'Rev', en_long: 'Revelation', verse_count: 22, },
];

