import React, { FC } from 'react';
import { useAtom } from 'jotai';

import { useBiblePolicy } from '../../context/BibleChapterSelectPolicy';
import { bibleAtom } from '../../context/BibleStore';

export const ChapterMovingButtons: FC = () => {
  const biblePolicy = useBiblePolicy();
  const [bible, setBible] = useAtom(bibleAtom);

  return <div
    className="flex justify-between items-start self-stretch flex-grow-0
    flex-shrink-0 h-14 overflow-hidden gap-[202px] px-4 py-[9px] bg-white
    border-t border-r-0 border-b-0 border-l-0 border-[#e9e9e9] lg:invisible visible lg:h-0"
  >
    <div
      className="flex justify-center items-center flex-grow-0 flex-shrink-0 h-[38px] relative overflow-hidden gap-3 px-3 py-[7px] rounded-lg"
      onClick={() => setBible((_bible: any) => biblePolicy.getPreviousChapter(_bible))}
    >
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="flex-grow-0 flex-shrink-0 w-[10.5px] h-3 relative"
        preserveAspectRatio="none"
      >
        <g clipPath="url(#clip0_86_1883)">
          <path
            d="M2.60186 5.41397C2.38154 5.63428 2.38154 5.99053 2.60186 6.2085L7.10186 10.7108C7.32217 10.9312 7.67842 10.9312 7.89639 10.7108C8.11435 10.4905 8.1167 10.1343 7.89639 9.91631L3.79482 5.81475L7.89873 1.71084C8.11904 1.49053 8.11904 1.13428 7.89873 0.91631C7.67842 0.698341 7.32217 0.695997 7.1042 0.91631L2.60186 5.41397Z"
            fill="#A3AAB6"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_86_1883">
            <rect
              width="10.5"
              height="12"
              fill="white"
              transform="translate(12 0.75) rotate(90)"
            ></rect>
          </clipPath>
        </defs>
      </svg>
      <p className="flex-grow-0 flex-shrink-0 text-[15px] font-medium text-right text-[#5e6268]">{biblePolicy.getPreviousChapter(bible).chapter}장</p>
    </div>
    <div
      className="flex justify-center items-center flex-grow-0 flex-shrink-0 h-[38px] relative overflow-hidden gap-3 px-3 py-[7px] rounded-lg"
      onClick={() => setBible((_bible: any) => biblePolicy.getNextChapter(_bible))}
    >
      <p className="flex-grow-0 flex-shrink-0 text-[15px] font-medium text-right text-[#5e6268]">{biblePolicy.getNextChapter(bible).chapter}장</p>
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="flex-grow-0 flex-shrink-0 w-[10.5px] h-3 relative"
        preserveAspectRatio="none"
      >
        <g clipPath="url(#clip0_86_1888)">
          <path
            d="M9.39814 6.58603C9.61846 6.36572 9.61846 6.00947 9.39814 5.7915L4.89814 1.28916C4.67783 1.06885 4.32158 1.06885 4.10361 1.28916C3.88564 1.50947 3.8833 1.86572 4.10361 2.08369L8.20518 6.18525L4.10127 10.2892C3.88096 10.5095 3.88096 10.8657 4.10127 11.0837C4.32158 11.3017 4.67783 11.304 4.8958 11.0837L9.39814 6.58603Z"
            fill="#A3AAB6"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_86_1888">
            <rect
              width="10.5"
              height="12"
              fill="white"
              transform="translate(0 11.25) rotate(-90)"
            ></rect>
          </clipPath>
        </defs>
      </svg>
    </div>
  </div>;
}
