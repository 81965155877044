export function isExistNameInContext(text: string, name: string): boolean {
  return createRegex(name).test(text);
}

export function createRegex(name: string): RegExp {
  if (name.length < 2) {
    return new RegExp(`(<|\\[| |^)(${name})(가|는|의|을|를|에게|조차|과|와|,|>)`, 'g');
  }
  return new RegExp(`(<|\\[| |^)(${name})(이|가|은|는|의|께|을|를|에게|조차|과|와|,|>|로)`, 'g');
}

export function createRegexRegion(name: string): RegExp {
  if (name.length < 2) {
    return new RegExp(`(<|\\[| |^)(${name})(가|는|의|을|를|에게|조차|과|와|,|>| |에)`, 'g');
  }
  return new RegExp(`(<|\\[| |^)(${name})(이|가|은|는|의|께|을|를|에게|조차|과|와|,|>|로| |에)`, 'g');
}
