import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { atom, useAtom, useAtomValue } from 'jotai';
import * as _ from 'lodash';
import { throttle } from 'throttle-debounce';
import * as qs from 'qs';

import { bibleAtom, selectedParagraphsAtom } from '../../context/BibleStore';
import { useMountEffect } from '@react-hookz/web/esm/useMountEffect';

export const isQuestionEnabledAtom = atom(false);

interface IChatMessages {
  role: 'user' | 'system' | 'assistant';
  content: string;
  name?: string;
}

const KEY_CODE_FOR_ENTER = 27;

export const QuestionBox: FC = () => {
  const { book, chapter } = useAtomValue(bibleAtom);
  const [isQuestionEnabled, setIsQuestionEnabled] = useAtom(isQuestionEnabledAtom);
  const selectedParagraphs = useAtomValue(selectedParagraphsAtom);
  const [askTexts, setAskTexts] = useState<IChatMessages[]>([]);
  const [currentAsk, setCurrentAsk] = useState('');
  const paragraphs = _.sortBy(Array.from(selectedParagraphs), 'paragraph').map(v =>
    ({
      ...v,
      sentence: v.sentence.replace(/<span.*?>(.*?)<\/span>/g, '$1'),
    })
  );
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [answer, setAnswer] = useState({
    text: '',
    isDone: false,
  });

  useEffect(() => {
    setAskTexts([]);
  }, [selectedParagraphs]);

  useEffect(() => {
    // document.body.style.overflow = isQuestionEnabled ? 'hidden' : 'auto';
    if (isQuestionEnabled) {
      textareaRef.current?.focus();
    }
  }, [isQuestionEnabled]);

  const handleKeyDown = useCallback((event: any) => {
    if (event.keyCode === KEY_CODE_FOR_ENTER) {
      setIsQuestionEnabled(false);
    }
  }, [setIsQuestionEnabled]);

  useMountEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  });

  const onClickQuestionSend = throttle(500, () => {
    setAskTexts(asks => [...asks, { role: 'user', content: currentAsk }]);
    setAnswer({ text: '', isDone: false });
    setTimeout(() => setCurrentAsk(''), 50);
    setAskTexts(text => [...text, { content: '', role: 'assistant' }]);
    const query = qs.stringify({
      bible: { book, chapter },
      paragraphs: paragraphs,
      qnaList: [...askTexts, { role: 'user', content: currentAsk }],
    });
    const eventSource = new EventSource(`${process.env.REACT_APP_API_URL_BASE}/completion?${query}`);
    eventSource.onmessage = (e) => {
      const parse = e.data;
      if (e.data === '[DONE]') {
        eventSource.close();
        setAnswer(_answer => ({ text: _answer.text, isDone: true }));
        return;
      }
      setAnswer(_answer => ({ text: _answer.text + parse, isDone: false }));
    };
  }, { debounceMode: true });

  useEffect(() => {
    setAskTexts(text => {
      if (_.last(text)?.role === 'assistant') {
        text.pop();
      }
      return [...text, { role: 'assistant', content: answer.text }];
    });
  }, [answer, setAskTexts]);

  if (!isQuestionEnabled) {
    return <></>;
  }

  return <div className={'fixed z-40 w-full h-full flex justify-center items-center'}>
    <div className={'w-240 h-3/4 bg-white rounded-3xl z-40 overflow-y-scroll'}>
      <div className={'px-10 py-5 border-b-gray-300 border-b-2'}>
        <div>
          "
          {
            paragraphs.length === 0 ? `${book} ${chapter}장` : paragraphs.map((paragraph, index) =>
              <span
                className={`relative hover:border-2 rounded text-black font-sans box-border mr-2 last:mr-0 my-0 cursor-pointer`}
                key={`paragraph-${index}`}
              >
                {paragraph.paragraph} {paragraph.sentence}
              </span>
            )
          }
          "
        </div>
        <div className={'mt-4 font-sans font-bold'}>에서 질문합니다.</div>
      </div>
      <div className={'px-10 pt-5 pb-1/5'}>
        {
          askTexts.map((text, index) =>
            <div
              className={`mb-3 ${text.role === 'assistant' ? 'font-bold' : 'font-sans'}`}
              key={`message-${index}`}
            >
              {text.content}
            </div>
          )
        }
      </div>
    </div>
    <div
      className={'absolute top-0 left-0 w-full h-full bg-black opacity-25'}
      onClick={() => setIsQuestionEnabled(false)}
    />
    <div
      className={'absolute bottom-0 left-0 w-full h-1/5 bg-gray-50 z-50 p-5'}
    >
      <textarea
        ref={textareaRef}
        className={'w-full h-full bg-auto p-3'}
        onChange={(e) => setCurrentAsk(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            setTimeout(() => onClickQuestionSend(), 50);
          }
        }}
        value={currentAsk}
      />
      <button
        className={'absolute right-6 bottom-6 bg-red-500 text-white rounded-full w-24 h-8'}
        onClick={onClickQuestionSend}
      >
        질문하기
      </button>
    </div>
  </div>;
};
