import React, { FC } from 'react';

import { BibleSelector } from './button/BibleSelector';
import { GPTButton } from './button/GPTButton';

export const MobileHeader:FC = () => {
  return <div className='fixed w-full z-30 bg-white flex items-center px-5 gap-3 h-16 border-b-2 border-gray-200 border-opacity-40'>
    <BibleSelector />
    <GPTButton />
  </div>
} // background: #7370F3;
