import React from 'react';
import { useSetAtom } from 'jotai';

import { isQuestionEnabledAtom } from '../question-box/QuestionBox';

export const GPTButton = () => {
  const setIsQuestionEnabled = useSetAtom(isQuestionEnabledAtom);

  return <div
    className={'absolute right-5 bg-purple w-20 h-10 rounded-md flex items-center justify-center font-bold font-sans text-base text-white cursor-pointer'}
    onClick={() => setIsQuestionEnabled(true)}
  >
    물어보기
  </div>
}
