import { FC } from 'react';
import { useAtom } from 'jotai';

import { isQuestionEnabledAtom } from '../question-box/QuestionBox';
import { selectedWordAtom } from '../../context/BibleStore';

export const FloatingQuestionButton: FC = () => {
  const [isQuestionEnabled, setIsQuestionEnabled] = useAtom(isQuestionEnabledAtom);
  const [selectedWord, setSelectedWord] = useAtom(selectedWordAtom);

  return <div
    className={`transition-opacity
    absolute z-30 -bottom-4 right-8 bg-purple xl:w-20 xl:h-20 w-16 h-16 rounded-[21px] 
    flex items-center justify-center font-sans font-semibold text-[48px] 
    text-white cursor-pointer lg:visible invisible cursor-pointer
    ${isQuestionEnabled ? 'xl:opacity-100 opacity-0' : ''}
    `}
    onClick={() => {
      if (selectedWord !== '') {
        setSelectedWord('');
        setIsQuestionEnabled(true);
        return;
      }
      setIsQuestionEnabled(!isQuestionEnabled);
    }}
  >
    ?
  </div>
}
