import { FC } from 'react';

export const ChapterItem: FC<{ chapter: number, isSelected: boolean, onClick: (value: number) => void }> = ({ chapter, isSelected, onClick }) => {
  return <div
    className={`flex w-full relative px-2 py-3 rounded-lg ${isSelected ? 'bg-[#7370f3]/10' : 'bg-white'}`}
    onClick={() => onClick(chapter)}
  >
    <p className={`w-full text-base text-center ${isSelected ? 'font-semibold text-[#7370f3]' : 'font-medium text-[#787c83]'}`}>{chapter}장</p>
  </div>
}
