import * as _ from 'lodash';
import { BIBLE_BOOK_TITLES, BIBLE_CHAPTER_MAP } from './BibleConstants';
import { atom, useAtomValue } from 'jotai';

interface IBible {
  book: string;
  chapter: string;
}

export class BibleChapterSelectPolicy {
  getNextChapter(bible: IBible): IBible {
    const maxChapter = BIBLE_CHAPTER_MAP[bible.book];
    const chapterIndex = parseInt(bible.chapter);
    if (chapterIndex >= maxChapter) {
      const bookIndex = _.findIndex(BIBLE_BOOK_TITLES, v => v === bible.book);
      if (bookIndex >= 65) {
        return bible;
      }
      const nextBook = BIBLE_BOOK_TITLES[Math.max(bookIndex + 1, 0)];
      return { book: nextBook, chapter: '1' };
    }
    return ({ book: bible.book, chapter: Math.min(parseInt(bible.chapter) + 1, maxChapter).toString() })
  }

  getPreviousChapter(bible: IBible): IBible {
    if (bible.chapter === '1') {
      const bookIndex = _.findIndex(BIBLE_BOOK_TITLES, v => v === bible.book);
      if (bookIndex === 0) {
        return bible;
      }
      const nextBook = BIBLE_BOOK_TITLES[Math.max(bookIndex - 1, 0)];
      const nextChapter = BIBLE_CHAPTER_MAP[nextBook];
      return { book: nextBook, chapter: nextChapter.toString() };
    }
    return ({ book: bible.book, chapter: Math.max(parseInt(bible.chapter) - 1, 1).toString() });
  }
}

export const useBiblePolicy = () => {
  return useAtomValue(biblePolicyAtom);
}

const biblePolicyAtom = atom(new BibleChapterSelectPolicy());
