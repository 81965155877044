import { FC, useEffect, useRef, useState } from 'react';
import { useAtom, useAtomValue } from 'jotai';
import * as qs from 'qs';
import * as _ from 'lodash';
import { throttle } from 'throttle-debounce';
import { v4 } from 'uuid';

import { bibleAskIdAtom, bibleAtom, selectedParagraphsAtom } from '../../context/BibleStore';
import { isQuestionEnabledAtom } from './QuestionBox';
import { IChatMessages } from './ChatMessages';
import { AskText } from './AskText';
import { useMediaQuery } from '@react-hookz/web';
import { mediaLarge } from '../media/MediaContant';

export const QuestionBox2: FC = () => {
  const [bibleAskId, setBibleAskId] = useAtom(bibleAskIdAtom);
  const { book, chapter } = useAtomValue(bibleAtom);
  const [isQuestionEnabled, setIsQuestionEnabled] = useAtom(isQuestionEnabledAtom);
  const selectedParagraphs = useAtomValue(selectedParagraphsAtom);
  const [askTexts, setAskTexts] = useState<IChatMessages[]>([]);
  const [currentAsk, setCurrentAsk] = useState('');
  const askRef = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery(mediaLarge);

  useEffect(() => {
    setBibleAskId(v4());
  }, [book, chapter, setBibleAskId]);

  const paragraphs = _.sortBy(Array.from(selectedParagraphs), 'paragraph').map(v =>
    ({
      ...v,
      sentence: v.sentence.replace(/<span.*?>(.*?)<\/span>/g, '$1'),
    })
  );
  const [answer, setAnswer] = useState({
    text: '',
    isDone: false,
  });
  console.log(answer, paragraphs);

  useEffect(() => {
    if (askTexts.length === 0) {
      return;
    }
    setAskTexts(text => {
      if (_.last(text)?.role === 'assistant') {
        text.pop();
      }
      return [...text, { role: 'assistant', content: answer.text }];
    });
  }, [answer, setAskTexts, askTexts.length]);

  const onClickQuestionSend = throttle(500, () => {
    setAskTexts(asks => [...asks, { role: 'user', content: currentAsk }]);
    setAnswer({ text: '', isDone: false });
    setTimeout(() => {
      setCurrentAsk('');
      askRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }, 50);
    setAskTexts(text => [...text, { content: '', role: 'assistant' }]);
    const query = qs.stringify({
      bible: { book, chapter },
      paragraphs: paragraphs,
      question: currentAsk,
      id: bibleAskId,
    });
    const eventSource = new EventSource(`${process.env.REACT_APP_API_URL_BASE}/ask-bible?${query}`);
    eventSource.onmessage = (e) => {
      const parse = e.data;
      if (e.data === '[DONE]') {
        eventSource.close();
        setAnswer(_answer => ({ text: _answer.text, isDone: true }));
        return;
      }
      setAnswer(_answer => ({ text: _answer.text + parse, isDone: false }));
      setTimeout(() => {
        askRef?.current?.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    };
    eventSource.onerror = (e) => {
      console.log(e);
    };
  }, { debounceMode: true });

  if (isMobile && isQuestionEnabled) {
    return <div
      className={`fixed right-0 top-0 bottom-10 left-0 px-4 pt-24 pb-10 z-20 flex justify-center`}
    >
      <div className={'relative max-w-[550px] max-h-full w-screen h-screen'}>
        <div
          className={`absolute rounded-full bg-purple 
        w-6 h-6 top-4 right-4 text-white cursor-pointer 
        flex justify-center items-center
      `}
          onClick={() => setIsQuestionEnabled(false)}
        >
          x
        </div>
        <div
          className={`max-h-full flex flex-col justify-start bg-white
      items-center overflow-hidden px-6 py-8 rounded-2xl 
      border border-gray-100 xl:visible visible
      transition-opacity duration-300 shadow-desc
      `}
        >
          <div className={`relative flex flex-col w-full gap-5 z-10 ${askTexts.length > 0 ? 'mb-6' : 'mb-1'}`}>
            <div
              className="flex justify-center items-center relative gap-2 pr-1"
            >
              <p className="w-full text-lg text-[#1c1d1f]">
            <span
              className="text-lg font-semibold text-[#1c1d1f]"
            >"{book} {chapter}장" </span>
                <span
                  className="text-lg font-light text-[#1c1d1f]"
                >에서 질문합니다.</span>
              </p>
            </div>
            {
              askTexts.length > 0 &&
              <div
                className="absolute -bottom-6 translate-y-full w-full h-[54px] bg-gradient-to-b from-white to-white/0"
              />
            }
          </div>
          <div
            className={'relative flex flex-col w-full h-full overflow-y-scroll pt-5'}
          >
            {
              askTexts.map((text, index) => (
                <AskText text={text} key={`ask-text-${index}`}/>
              ))
            }
            <div ref={askRef}/>
          </div>
          <textarea
            className="w-full h-[141px] overflow-hidden rounded-[15px] bg-white border border-[#7370f3] mb-6 p-4 z-30"
            onChange={(e) => setCurrentAsk(e.target.value)}
            value={currentAsk}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                setTimeout(() => onClickQuestionSend(), 50);
              }
            }}
          />
          <div
            className="flex justify-center items-center w-full h-12 gap-2.5 px-5 py-3 rounded-xl bg-[#7370f3] cursor-pointer"
            onClick={onClickQuestionSend}
          >
            <p className="font-semibold text-left text-white">
              질문하기
            </p>
          </div>
        </div>
      </div>
    </div>;
  }

  return <div
    className={`fixed right-10 top-0 h-screen max-h-full pt-24 pb-10 z-20
    ${isQuestionEnabled ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}
    `}
  >
    <div
      className={`relative max-h-full flex flex-col justify-start bg-white
      items-center overflow-hidden px-6 py-8 rounded-2xl border border-gray-100 xl:visible visible
      transition-opacity duration-300 shadow-desc`}
    >
      <div
        className={`
      absolute rounded-full bg-purple w-6 h-6 top-4 right-4 text-white cursor-pointer 
      flex justify-center items-center
      `}
        onClick={() => setIsQuestionEnabled(false)}
      >
        x
      </div>
      <div className={`relative flex flex-col w-[281px] gap-5 z-10 ${askTexts.length > 0 ? 'mb-6' : 'mb-1'}`}>
        <div
          className="flex justify-center items-center relative gap-2 pr-1"
        >
          <p className="w-[277px] text-lg text-[#1c1d1f]">
            <span
              className="text-lg font-semibold text-[#1c1d1f]"
            >"{book} {chapter}장" </span>
            <span
              className="text-lg font-light text-[#1c1d1f]"
            >에서 질문합니다.</span>
          </p>
        </div>
        {
          askTexts.length > 0 &&
          <div
            className="absolute -bottom-6 translate-y-full w-full h-[54px] bg-gradient-to-b from-white to-white/0"
          />
        }
      </div>
      <div
        className={'relative flex flex-col w-full h-full overflow-y-scroll pt-5'}
      >
        {
          askTexts.map((text, index) => (
            <AskText text={text} key={`ask-text-${index}`}/>
          ))
        }
        <div ref={askRef}/>
      </div>
      <textarea
        className="w-[281px] h-[141px] overflow-hidden rounded-[15px] bg-white border border-[#7370f3] mb-6 p-4 z-30"
        onChange={(e) => setCurrentAsk(e.target.value)}
        value={currentAsk}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            setTimeout(() => onClickQuestionSend(), 50);
          }
        }}
      />
      <div
        className="flex justify-center items-center w-[281px] h-12 gap-2.5 px-5 py-3 rounded-xl bg-[#7370f3] cursor-pointer"
        onClick={onClickQuestionSend}
      >
        <p className="font-semibold text-left text-white">
          질문하기
        </p>
      </div>
    </div>
  </div>;
};
