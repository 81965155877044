import React, { FC, useCallback } from 'react';
import { useAtom } from 'jotai/index';
import { bibleAtom } from '../../context/BibleStore';
import { useBiblePolicy } from '../../context/BibleChapterSelectPolicy';
import { FloatingQuestionButton } from './FloatingQuestionButton';
import { useMountEffect } from '@react-hookz/web';
import * as _ from 'lodash';
import { BIBLE_BOOK_TITLES, BIBLE_CHAPTER_MAP } from '../../context/BibleConstants';

export const ChapterSelector: FC = () => {
  const biblePolicy = useBiblePolicy();
  const [bible, setBible] = useAtom(bibleAtom);

  const handleKeyDown = useCallback((event: any) => {
    if (event.keyCode === 37) { // 왼쪽 화살표
      setBible(_bible => {
        if (_bible.chapter === '1') {
          const bookIndex = _.findIndex(BIBLE_BOOK_TITLES, v => v === _bible.book);
          if (bookIndex === 0) {
            return _bible;
          }
          const nextBook = BIBLE_BOOK_TITLES[Math.max(bookIndex - 1, 0)];
          const nextChapter = BIBLE_CHAPTER_MAP[nextBook];
          return { book: nextBook, chapter: nextChapter.toString() };
        }
        return ({ book: _bible.book, chapter: Math.max(parseInt(_bible.chapter) - 1, 1).toString() });
      });
    } else if (event.keyCode === 39) { // 오른쪽 화살표
      setBible(_bible => {
        const maxChapter = BIBLE_CHAPTER_MAP[_bible.book];
        const chapterIndex = parseInt(_bible.chapter);
        if (chapterIndex >= maxChapter) {
          const bookIndex = _.findIndex(BIBLE_BOOK_TITLES, v => v === _bible.book);
          if (bookIndex >= 65) {
            return _bible;
          }
          const nextBook = BIBLE_BOOK_TITLES[Math.max(bookIndex + 1, 0)];
          return { book: nextBook, chapter: '1' };
        }
        return ({ book: _bible.book, chapter: Math.min(parseInt(_bible.chapter) + 1, maxChapter).toString() })
      });
    }
  }, [setBible]);

  useMountEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  });

  return <div
    className="fixed bottom-[32px] w-[640px] flex justify-center z-50"
  >
    <div className={'w-[205px] flex justify-between items-center gap-3 p-2 rounded-xl bg-white shadow-desc lg:visible invisible'}>
      <div
        className="flex justify-center items-center w-auto h-8 relative overflow-hidden gap-3 px-3 py-1 rounded-lg cursor-pointer"
        onClick={() => setBible((_bible: any) => biblePolicy.getPreviousChapter(_bible))}
      >
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="flex-grow-0 flex-shrink-0 w-[10.5px] h-3 relative"
          preserveAspectRatio="none"
        >
          <g clipPath="url(#clip0_206_7480)">
            <path
              d="M2.60234 5.41397C2.38203 5.63428 2.38203 5.99053 2.60234 6.2085L7.10234 10.7108C7.32266 10.9312 7.67891 10.9312 7.89687 10.7108C8.11484 10.4905 8.11719 10.1343 7.89687 9.91631L3.79531 5.81475L7.89922 1.71084C8.11953 1.49053 8.11953 1.13428 7.89922 0.91631C7.67891 0.698341 7.32266 0.695997 7.10469 0.91631L2.60234 5.41397Z"
              fill="#A3AAB6"
            ></path>
          </g>
          <defs>
            <clipPath id="clip0_206_7480">
              <rect
                width="10.5"
                height="12"
                fill="white"
                transform="translate(12 0.75) rotate(90)"
              ></rect>
            </clipPath>
          </defs>
        </svg>
        <p className="text-[15px] font-medium text-right text-[#5e6268]">{biblePolicy.getPreviousChapter(bible).chapter}장</p>
      </div>
      <div
        className="flex justify-center items-center w-[71px] h-8 relative overflow-hidden gap-3 px-3 py-1 rounded-lg cursor-pointer"
        onClick={() => setBible((_bible: any) => biblePolicy.getNextChapter(_bible))}
      >
        <p className="flex-grow-0 flex-shrink-0 text-[15px] font-medium text-right text-[#5e6268]">{biblePolicy.getNextChapter(bible).chapter}장</p>
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="flex-grow-0 flex-shrink-0 w-[10.5px] h-3 relative"
          preserveAspectRatio="none"
        >
          <g clipPath="url(#clip0_206_7486)">
            <path
              d="M9.39766 6.58603C9.61797 6.36572 9.61797 6.00947 9.39766 5.7915L4.89766 1.28916C4.67734 1.06885 4.32109 1.06885 4.10312 1.28916C3.88516 1.50947 3.88281 1.86572 4.10312 2.08369L8.20469 6.18525L4.10078 10.2892C3.88047 10.5095 3.88047 10.8657 4.10078 11.0837C4.32109 11.3017 4.67734 11.304 4.89531 11.0837L9.39766 6.58603Z"
              fill="#A3AAB6"
            ></path>
          </g>
          <defs>
            <clipPath id="clip0_206_7486">
              <rect
                width="10.5"
                height="12"
                fill="white"
                transform="translate(0 11.25) rotate(-90)"
              ></rect>
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
    <FloatingQuestionButton />
  </div>
}
