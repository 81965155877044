import { atomWithStorage, loadable } from 'jotai/utils';
import { atom } from 'jotai';
import { v4 as uuidV4 } from 'uuid';

import { BIBLE_MAPPING_TABLE } from './BibleConstants';
import { totalPeopleAtom } from '../App';
import { createRegex, createRegexRegion } from './ContextPolicy';
import { totalRegionAtom } from '../components/BibleConstant';

export const bibleAskIdAtom = atomWithStorage('bible-ask-id', uuidV4());

export const bibleAtom = atomWithStorage('bible-config', {
  book: '창세기',
  chapter: '1',
});

export const selectedParagraphsAtom = atom<Set<IParagraph>>(new Set([]));
export const selectedWordAtom = atom<string>('');

export interface IParagraph {
  sentence: string;
  paragraph: number;
}

export const bibleParagraphs = atom(async (get) => {
  const { book, chapter } = get(bibleAtom);

  const totalPeople = get(totalPeopleAtom);
  const totalRegion = get(totalRegionAtom);
  console.log(totalRegion)
  const def = BIBLE_MAPPING_TABLE.find(v => v.kr_long === book) ?? { en_short: 'gen' };
  const response = await fetch(`${process.env.REACT_APP_API_URL_BASE}/${def.en_short}/${chapter}`);
  const paragraphs = await response.json();

  paragraphs.forEach((paragraph: any) => {
    totalPeople.filter(v => v.priority != null && v.priority > 1).forEach(person => {
      const regex = createRegex(person.name);
      paragraph.sentence = paragraph.sentence.replace(regex, `$1<span class="text-purple font-bold cursor-pointer">$2</span>$3`);
    })

    totalRegion.forEach(region => {
      const regex = createRegexRegion(region.name);
      paragraph.sentence = paragraph.sentence.replace(regex, `$1<span class="text-yellow-800 font-bold cursor-pointer">$2</span>$3`);
    })
  })
  return paragraphs;
})

export const fetchBibleParagraphs = loadable<any>(bibleParagraphs);

export const bibleBooksAtom = atom<any[]>([]);

bibleBooksAtom.onMount = (setAtom ) => {
  fetch(`${process.env.REACT_APP_API_URL_BASE}/contents`)
    .then((res) => res.json().then(books => setAtom(books)))
}
