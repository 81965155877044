import { atom, useAtom, useAtomValue } from 'jotai';
import { useEffect, useRef, useState } from 'react';
import * as _ from 'lodash';

import { bibleAtom, bibleBooksAtom } from '../../context/BibleStore';
import { BookItem } from './BookItem';
import { ChapterItem } from './ChapterItem';
import { scroller } from 'react-scroll';

export const isBookSelectMenuAtom = atom(false);


export const BookSelectMenuView = () => {
  const books = useAtomValue(bibleBooksAtom);
  const [bible, setBible] = useAtom(bibleAtom);
  const [selectedBook, setSelectedBook] = useState<string>(bible.book);
  const [selectedChapter, setSelectedChapter] = useState<number>(Number(bible.chapter));
  const def = books.find(v => v.kr_long === selectedBook);
  const chapters = _.range(1, (def?.verse_count ?? 0) + 1);
  const [isBookSelectMenu, setIsBookSelectMenu] = useAtom(isBookSelectMenuAtom);
  const chapterRef = useRef<HTMLDivElement>(null);
  const SHORT_CUT_LIST = ['창', '욥', '단', '학', '마', '갈', '히', '계'];

  const onClickShortCut = (cut: string) => {
    const find = books.find(book => book.kr_short === cut);
    if (find == null) {
      return;
    }
    setSelectedBook(find.kr_long);
    scroller.scrollTo(find.kr_long, {
      duration: 500,
      smooth: 'easeInOutQuint',
      containerId: 'book-container',
      offset: -window.innerHeight / 2 + 75,
    });
  };

  useEffect(() => {
    if (isBookSelectMenu) {
      setSelectedBook(bible.book);
      // chapterRef?.current?.scrollTo({ top: 0 });
      scroller.scrollTo(bible.book, {
        duration: 0,
        smooth: 'easeInOutQuint',
        containerId: 'book-container',
        offset: -window.innerHeight / 2 + 75,
      });
      setSelectedChapter(Number(bible.chapter));
    }
  }, [isBookSelectMenu, setSelectedBook, bible.book, bible.chapter]);

  const onClickBible = (value: string) => {
    setSelectedBook(value);
    chapterRef?.current?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const onClickChapter = (value: number) => {
    setBible({ book: selectedBook, chapter: value.toString() });
    setSelectedChapter(value);
    setIsBookSelectMenu(false);
  };

  return <>
    <div
      className={`fixed lg:rounded-[16px] lg:w-[195px] xl:w-[250px] w-[317px] lg:h-auto h-full 
      lg:top-[100px] lg:bottom-10 top-0 overflow-hidden bg-white z-50 lg:p-3 
      transition-all lg:left-5 xl:left-10 ${!isBookSelectMenu ? '-left-[317px]' : 'left-0'}
      `}
      style={{ 'boxShadow': '0px 4px 25px 0 rgba(0,0,0,0.15)' }}
    >
      <div
        className="w-full flex items-center justify-end h-10 pr-5 cursor-pointer lg:invisible visible"
        onClick={() => setIsBookSelectMenu(false)}
      >
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="w-[9.72px] h-2.5"
          preserveAspectRatio="none"
        >
          <path
            d="M1.06201 1L10.7825 11"
            stroke="#A3AAB6"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
          <path
            d="M1.06201 11L10.7825 1.00001"
            stroke="#A3AAB6"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </svg>
      </div>
      <div
        className="flex flex-col lg:w-[90px] xl:w-[118px] w-[134px] h-auto absolute pr-[18px] left-3 lg:top-3 bottom-3 top-12 gap-2 overflow-y-scroll"
        id={'book-container'}
      >
        {books.map((book, index) =>
          <BookItem
            title={book.kr_long}
            onClick={onClickBible}
            isSelected={book.kr_long === selectedBook}
            key={`book-${index}`}
          />)}
      </div>
      <div
        className="flex flex-col lg:w-[80px] xl:w-[100px] w-[134px] h-auto lg:pr-3 absolute lg:left-[115px] xl:left-[148px] left-[171px] lg:top-3 bottom-3 top-12 gap-2 overflow-y-scroll"
        ref={chapterRef}
      >
        {
          chapters.map((chapter, index) =>
            <ChapterItem
              chapter={chapter}
              isSelected={chapter === selectedChapter && selectedBook === bible.book}
              onClick={onClickChapter}
              key={`chapter-${index}`}
            />)
        }
      </div>
      <div className="w-px h-auto absolute lg:left-[105px] xl:left-[132px] left-[157px] lg:top-3 bottom-3 top-12 bg-[#eff1f4]" />
      <div
        className="flex flex-col justify-start items-start absolute lg:left-[88px] xl:left-[115px] left-[139px] top-1/2 -translate-y-1/2 gap-8 px-1 py-4 rounded-[40px] bg-[#f4f6f8]"
      >
        {
          SHORT_CUT_LIST.map((shortcut, index) =>
            <p
              className="flex-grow-0 flex-shrink-0 text-xs text-center text-[#a3aab6]"
              onClick={() => onClickShortCut(shortcut)}
              key={`shortcut-${index}`}
            >
              {shortcut}
            </p>
          )
        }
      </div>
      <div
        className="flex absolute top-1/2 -translate-y-1/2 left-[-11px] overflow-hidden p-4 rounded-2xl bg-[#a3aab6]/[0.15] lg:invisible"
      >
        <p
          className="flex-grow-0 flex-shrink-0 text-[21px] font-medium text-center text-[#89909b]"
        >
          {def?.kr_short}
        </p>
      </div>
    </div>
    {isBookSelectMenu &&
      <div
        className={'absolute z-40 w-full h-full top-0 left-0'}
        onClick={() => setIsBookSelectMenu(false)}
      />
    }
  </>;
};
