import React, { FC, useCallback, useRef } from 'react';
import { useAtom, useSetAtom } from 'jotai';
import { useMountEffect } from '@react-hookz/web';

import { IParagraph, selectedParagraphsAtom, selectedWordAtom } from '../../context/BibleStore';
import { isQuestionEnabledAtom } from '../question-box/QuestionBox';

export const Paragraph: FC<{ paragraph: any }> = ({ paragraph }) => {
  const [selectedWord, setSelectedWord] = useAtom(selectedWordAtom);
  const setIsQuestion = useSetAtom(isQuestionEnabledAtom);
  const ref = useRef<HTMLDivElement>(null);
  const [selectedParagraphs, setSelectedParagraphs] = useAtom(selectedParagraphsAtom);

  const eventListener = useCallback((e: any) => {
    setIsQuestion(false);
    const div = e.target as HTMLDivElement;
    setSelectedWord(_word => {
      return _word !== div.innerText ? div.innerText : '';
    });
    e.stopPropagation();
  }, [setSelectedWord, setIsQuestion]);

  useMountEffect(() => {
    if (ref.current) {
      ref.current.childNodes.forEach((v) => {
        v.removeEventListener('click', eventListener);
        v.addEventListener('click', eventListener);
      });
    }
  });

  const onClickParagraph = useCallback((paragraph: IParagraph) => {
    if (selectedWord !== '') {
      setSelectedWord('');
      return;
    }
    if (selectedParagraphs.has(paragraph)) {
      selectedParagraphs.delete(paragraph);
    } else {
      selectedParagraphs.add(paragraph);
    }
    setSelectedParagraphs(new Set(selectedParagraphs));
  }, [setSelectedWord, setSelectedParagraphs, selectedParagraphs, selectedWord]);

  return <>
    {
      paragraph.title != null &&
      <p className="text-left text-gray-500 my-4">
        {paragraph.title}
      </p>
    }
    <div
      className={`
          relative lg:hover:border-2 ml-4 mb-2 py-0 leading-7 rounded 
          text-black box-border my-0 cursor-pointer px-1 
          ${selectedParagraphs.has(paragraph) ? 'bg-blue-200' : ''}
          `}
    >
      <div className={'absolute text-sm top-0 left-0 -translate-x-full text-main-gray pr-1.5 py-0 leading-7'}>
        {paragraph.paragraph}
      </div>
      <div
        onClick={() => onClickParagraph(paragraph)}
        dangerouslySetInnerHTML={{ __html: paragraph.sentence }}
        ref={ref}
      />
    </div>
  </>;
};
