import React, { FC } from 'react';
import { useMediaQuery } from '@react-hookz/web';

import { MobileHeader } from './MobileHeader';
import { mediaLarge } from './media/MediaContant';

export const Header: FC = () => {
  const isMobile = useMediaQuery(mediaLarge);
  if (isMobile) {
    return <MobileHeader />
  }

  return <div className='fixed w-full z-30 bg-white flex items-center justify-center px-3 gap-6 h-16 lg:border-b-[1px] lg:border-black/10'>
    <div className="absolute sm:left-10 left-4 text-2xl font-bold font-sans lg:visible invisible">
      Daible
    </div>
  </div>
}
