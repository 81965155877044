import { FC } from 'react';
import { Element } from 'react-scroll';

export const BookItem: FC<{ title: string, isSelected: boolean, onClick: (value: string) => void }> = ({ title, isSelected, onClick  }) => {
  return <Element
    name={title}
    className={`
    flex justify-start items-start self-stretch flex-grow-0 
    flex-shrink-0 relative overflow-hidden gap-2.5 
    px-2 py-3 rounded-lg ${isSelected ? 'bg-[#7370f3]/10' : '' }
    `}
    onClick={() => onClick(title)}
  >
    <p className={`flex-grow w-[118px] text-base text-center ${isSelected ? 'font-semibold text-[#7370f3]' : 'font-medium text-[#787c83]'}`}>{title}</p>
  </Element>
}
