import React, { FC } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';

import { bibleAtom } from '../../context/BibleStore';
import { isBookSelectMenuAtom } from '../book-select-menu-view/BookSelectMenuView';

export const BibleSelector: FC = () => {
  const bible = useAtomValue(bibleAtom);
  const setIsBookSelectMenu = useSetAtom(isBookSelectMenuAtom);

  return <div
    className={'flex gap-3 items-center cursor-pointer'}
    onClick={() => setIsBookSelectMenu(true)}
  >
    <h1 className="text-2xl font-bold mt-1">
      {bible.book} {bible.chapter}장
    </h1>
    <div
      className="flex flex-col justify-start items-start gap-[3px]"
    >
      <svg
        width="7"
        height="5"
        viewBox="0 0 7 5"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="flex-grow-0 flex-shrink-0"
        preserveAspectRatio="none"
      >
        <path
          d="M3.08818 0.728451C3.31596 0.508811 3.68586 0.508811 3.91364 0.728451L6.82917 3.53985C7.05694 3.75949 7.05694 4.11619 6.82917 4.33583C6.60139 4.55547 6.23148 4.55547 6.00371 4.33583L3.5 1.92154L0.99629 4.33407C0.768515 4.55371 0.398607 4.55371 0.170832 4.33407C-0.0569439 4.11443 -0.0569439 3.75774 0.170832 3.5381L3.08636 0.726694L3.08818 0.728451Z"
          fill="#A0ABBA"
        ></path>
      </svg
      >
      <svg
        width="7"
        height="5"
        viewBox="0 0 7 5"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="flex-grow-0 flex-shrink-0"
        preserveAspectRatio="none"
      >
        <path
          d="M3.91182 4.27155C3.68404 4.49119 3.31414 4.49119 3.08636 4.27155L0.170832 1.46015C-0.0569436 1.24051 -0.0569436 0.883809 0.170832 0.664169C0.398608 0.444528 0.768516 0.444528 0.996291 0.664169L3.5 3.07846L6.00371 0.665926C6.23149 0.446286 6.60139 0.446286 6.82917 0.665926C7.05694 0.885567 7.05694 1.24226 6.82917 1.4619L3.91364 4.27331L3.91182 4.27155Z"
          fill="#A0ABBA"
        ></path>
      </svg>
    </div>
  </div>
}
