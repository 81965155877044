import React, { FC } from 'react';
import { useAtomValue } from 'jotai';

import { selectedWordAtom } from '../../context/BibleStore';
import { totalPeopleAtom } from '../../App';
import { totalRegionAtom } from '../BibleConstant';

export const DescriptionView: FC = () => {
  const selectedWord = useAtomValue(selectedWordAtom);
  const totalPeople = useAtomValue(totalPeopleAtom);
  const totalRegion = useAtomValue(totalRegionAtom);

  const selectedCard = totalRegion.find(v => v.name === selectedWord) ?? totalPeople.find(v => v.name === selectedWord);
  const opacityCss = selectedCard == null ? 'opacity-30' : 'opacity-100';
  return <div
    className={`flex flex-col w-full lg:h-full transition-opacity
    h-[332px] gap-2 px-5 lg:pt-12 pt-8 pb-8 bg-white lg:border-0 border-2 
    border-t border-[#eff1f4] overflow-y-scroll relative ${opacityCss}`}
  >
    <p className="w-full text-[22px] font-semibold text-left text-[#1c1d1f]">
      {selectedCard?.name}
    </p>
    {selectedCard?.meaning &&
      <p className="w-full text-base font-light text-left text-[#1c1d1f] mb-2">
        뜻: {selectedCard?.meaning}
      </p>
    }
    <p className="w-full text-base text-left whitespace-pre-wrap">
      {selectedCard?.description}
    </p>
  </div>;
}
