import { FC } from 'react';

import { IChatMessages } from './ChatMessages';

export const AskText: FC<{ text: IChatMessages }> = ({ text }) => {
  if (text.role !== 'user') {
    return <div
      className={
        'flex justify-start items-center self-start ' +
        'relative gap-2.5 px-4 py-3 rounded-tr-[15px] ' +
        'rounded-bl-[15px] rounded-br-[15px] bg-[#7370f3]/10 mb-6'
      }
    >
      <p className="flex-grow w-[249px] text-base text-left text-[#1c1d1f]">
        {text.content === '' ? 'Loading...' : text.content}
      </p>
    </div>;
  }

  return <div
    className={`flex justify-start items-start relative self-end
      px-4 py-3 rounded-tl-[15px] rounded-bl-[15px] rounded-br-[15px] bg-[#eff1f4] mb-6`}
  >
    <p className="max-w-[280px] text-left text-[#1c1d1f]">{text.content}</p>
  </div>;
};
