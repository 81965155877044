import React from 'react';
import { atom } from 'jotai';
import { useMountEffect } from '@react-hookz/web';

import './App.css';
import { Loading } from './components/loading/Loading';
import { BibleView } from './components/bible-view/BibleView';
import { DescriptionAndArrowsView } from './components/bible-view/DescriptionAndArrowsView';
import { Header } from './components/Header';
import { BookSelectMenuView } from './components/book-select-menu-view/BookSelectMenuView';
import { QuestionBox2 } from './components/question-box/QuestionBox2';
import { ChapterSelector } from './components/button/ChapterSelector';

export const totalPeopleAtom = atom<any[]>([]);

totalPeopleAtom.onMount = (setAtom ) => {
  fetch(`${process.env.REACT_APP_API_URL_BASE}/get-bible-people`)
    .then((response) => response.json().then(data => setAtom(data)));
}

function App() {
  useMountEffect(() => {
    document.body.style.overflow = 'hidden';
  })
  return (
    <div>
      <Loading />
      <QuestionBox2 />
      <Header />
      <div className='sm-md:w-160 w-full md:px-0 w-full pt-16 flex flex-col items-center mx-auto'>
        <BibleView />
        <ChapterSelector />
      </div>
      <BookSelectMenuView />
      <DescriptionAndArrowsView />
    </div>
  );
}

export default App;
