import React, { FC, useEffect } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';

import { bibleAtom, fetchBibleParagraphs, IParagraph, selectedParagraphsAtom } from '../../context/BibleStore';
import { Paragraph } from './Paragraph';
import { useMediaQuery } from '@react-hookz/web';
import { mediaLarge } from '../media/MediaContant';

export const BibleView: FC = () => {
  const bible = useAtomValue(bibleAtom);
  const paragraphsLoadable = useAtomValue(fetchBibleParagraphs);
  const setSelectedParagraphs = useSetAtom(selectedParagraphsAtom);
  const isMobile = useMediaQuery(mediaLarge)

  useEffect(() => {
    setSelectedParagraphs(new Set());
  }, [bible, setSelectedParagraphs]);

  if (paragraphsLoadable.state !== 'hasData') {
    return <div></div>;
  }

  const paragraphs = paragraphsLoadable.data as any[];

  return <div className="sm:pt-6 pt-4 px-5 pr-4 lg:pb-16 pb-45vh h-bible font-gangwon overflow-y-scroll">
    {
      !isMobile &&
      <h1 className="text-[26px] font-bold mt-1 ml-2 mb-3">
        {bible.book} {bible.chapter}장
      </h1>
    }
    {
      paragraphs.map((paragraph: IParagraph, index) =>
        <Paragraph paragraph={paragraph} key={`paragraph-${index}`} />
      )
    }
    <div className={'h-5'}/>
  </div>;
};
