import React, { FC } from 'react';
import { useAtomValue } from 'jotai';

import { selectedWordAtom } from '../../context/BibleStore';
import { totalPeopleAtom } from '../../App';
import { ChapterMovingButtons } from '../button/ChapterMovingButtons';
import { DescriptionView } from './DescriptionView';
import { totalRegionAtom } from '../BibleConstant';

export const DescriptionAndArrowsView: FC = () => {
  const selectedWord = useAtomValue(selectedWordAtom);
  const totalPeople = useAtomValue(totalPeopleAtom);
  const totalRegion = useAtomValue(totalRegionAtom);
  const selectedCard = totalRegion.find(v => v.name === selectedWord) ?? totalPeople.find(v => v.name === selectedWord);

  return <div
    className={`fixed flex flex-col w-full z-30
    shadow-desc lg:left-auto xl:bg-transparent bg-white 
    lg:top-0 xl:w-1/4 lg:w-1/5 left-0 w-full lg:h-full h-[388px] transition-all duration-500 
    ${selectedCard != null ? 'lg:right-0 bottom-0 opacity-100' : 'lg:-right-full -bottom-[322px] lg:opacity-0 opacity-100 '}
    `}
  >
    <ChapterMovingButtons/>
    <DescriptionView/>
  </div>;
};
